<template>
  <div style="margin-top: 20px">
    <div class="recent-posts-title">
      Contact US
    </div>
    <div class="recent-posts-card">
      <div class="card-content" style="padding-bottom: 20px !important">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Full Name"
              required
              filled
              dense
              rounded
          ></v-text-field>

          <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
              filled
              dense
              rounded
          ></v-text-field>

          <v-textarea
              v-model="message"
              :rules="messageRules"
              label="Message"
              required
              filled
              dense
              rounded
          ></v-textarea>


          <v-btn
              :disabled="!valid"
              color="#85586F"
              @click="validate"
          >

            <span style="color: white" class="mx-2">Validate</span>
            <v-progress-circular
                v-if="contactLoad === true"
                indeterminate
                color="#FFFFFF"
                size="20"
            ></v-progress-circular>
          </v-btn>
        </v-form>
        <v-alert dismissible class="mt-5" v-if="contactAlert === 1" type="success">   Your message has been send succesfully.</v-alert>
        <v-alert dismissible class="mt-5" v-if="contactAlert === 2" type="error">   Error</v-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 100) || 'Name must be less than 100 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    message: '',
    messageRules: [
      v => !!v || 'Message is required',
      v => (v && v.length <= 500) || 'Message must be less than 500 characters',
    ],
    magasin: {},
    contactLoad: false,
    contactAlert: 0,
  }),
  methods: {
    validate () {
      if(this.$refs.form.validate()) {
        this.contactLoad = true
        const info = {
          full_name: this.name,
          email: this.email,
          message: this.message,
        };
        this.$http.post("contact", info)
            .then(response => {
              this.contactLoad = false
              console.log(response.data)
              if(response.data.success === true){
                this.contactAlert = 1
              }else{
                this.contactAlert = 2
              }
              this.reset()
            })
            .catch(error => {
              this.contactLoad = false
              this.reset()
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
      }
    },
    reset () {
      this.$refs.form.reset()
    },
  }
}
</script>

<style scoped>
.recent-posts-title {
  background-color: #85586F;
  color: white;
  padding: 15px;
  font-weight: bold;
  border-radius: 3px;
}
.recent-posts-card {
  background-color: white;

  margin-top: 1px;
  margin-bottom: 1px;
}
</style>