<template>
  <v-app class="pb-0 mb-0">
    <div class="body">
        <Header :magasin="magasin"/>
          <div class="columns my-container2">
            <div class="column is-two-thirds">
              <Carousel :events="events" />

              <TimeTable :year1st="year1st"/>
<!--              <ExamesTimeTable :exyear1st="exyear1st" :exyear2nd="exyear2nd" :exyear3rd="exyear3rd" />-->
<!--              <SchoolSupplies />-->

                <TourRegistration :year1st="year1st"/>

            </div>
            <div class="column">
              <RecentPost class="hidden-md-and-down" :events="events"/>
              <WorkingHours :magasin="magasin"/>
              <ContactForm />
              <SchoolLocation />
            </div>
          </div>
        <Footer :magasin="magasin"/>
      <v-overlay :value="overlay" color="white" :opacity="1">
        <v-progress-circular
            indeterminate
            size="60"
            color="brown"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>

<script>
    import Header from '../components/Header';
    import Footer from '../components/Footer';
    import Carousel from '../components/Carousel';
    import TimeTable from '../components/TimeTable';
    //import SchoolSupplies from '../components/SchoolSupplies';
    import RecentPost from "@/components/RecentPost";
    import WorkingHours from "@/components/WorkingHours";
    import ContactForm from "@/components/ContactForm";
    import SchoolLocation from "@/components/SchoolLocation";
    import TourRegistration from "@/components/TourRegistration.vue";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Home',
        components: {
          TourRegistration,
          SchoolLocation,
          ContactForm,
          WorkingHours,
          RecentPost,
            Header,
            Carousel,
            TimeTable,
            //SchoolSupplies,
            Footer
        },
        data: () => ({
          overlay: false,
          magasin: {},
          year1st: [],
          year2nd: [],
          year3rd: [],
          exyear1st: [],
          exyear2nd: [],
          exyear3rd: [],
          events: []
        }),
      created() {
        this.initialize()
      },
      mounted() {
        document.title = 'Home'
      },
      methods: {
        initialize() {
          this.overlay = true
          this.$http.get("init").then(response => {
            if (response.data != null) {
              this.magasin = response.data.magasin;
              this.year1st = response.data.tours
              this.year2nd = response.data.year2nd
              this.year3rd = response.data.year3rd
              this.exyear1st = response.data.exyear1st
              this.exyear2nd = response.data.exyear2nd
              this.exyear3rd = response.data.exyear3rd
              this.events = response.data.events
              this.overlay = false
            }
          }).catch(error => {
            console.log(error)
            this.overlay = false
          })
        }
      }
    }
</script>

<style>
    .body {
        background-color: #E9EDF0;
    }
    .my-container2 {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;
    }
</style>
