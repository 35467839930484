<template>
  <section class="timetable pb-0" style="min-height: 0">
    <v-row class="text-center">
      <h1 style="font-size: 20px; margin: 20px;">Tour Registration</h1>
      <p class="text-left ml-6 mr-2">
        Before registering, view all the tours that we offer with their special price, then fill in your information, then click on the confirmation button. After that, we will provide all the tour requirements and then contact you by phone and email.
      </p>
    </v-row>
    <div class="card-content mt-2">
        <v-form
            style="min-height: 0"
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-row>
            <v-text-field
                v-model="firstName"
                :rules="nameRules"
                label="First Name"
                required
                filled
                dense
                rounded
            ></v-text-field>
            <div class="mx-2"></div>
            <v-text-field
                v-model="lastName"
                :rules="nameRules"
                label="Last Name"
                required
                filled
                dense
                rounded
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                filled
                dense
                rounded
            ></v-text-field>
            <div class="mx-2"></div>
            <v-text-field
                v-model="phone"
                :rules="phoneRules"
                label="Phone"
                required
                filled
                dense
                rounded
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
                :items="genders"
                label="Gender"
                required
                filled
                dense
                rounded
                v-model="gender"
                :rules="genderRules"
            ></v-select>
            <div class="mx-2"></div>
            <v-select
                :items="paymentMethods"
                label="Payment Methode"
                required
                filled
                dense
                rounded
                v-model="paymentMethod"
                :rules="paymentMethodRules"
            ></v-select>
          </v-row>

          <v-row>
            <v-select
                :items="year1st"
                label="Tour Type"
                item-text="title"
                item-value="id"
                required
                filled
                dense
                rounded
                v-model="tourType"
                :rules="tourTypeRules"
            ></v-select>
            <div class="mx-2"></div>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="Picker in menu"
                    readonly
                    required
                    filled
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-row>

          <v-row>
            <v-btn
                :disabled="!valid"
                color="#85586F"
                @click="validate"
            >
              <span style="color: white" class="mx-2">
                Validate
                <span v-if="tourType != null">
                  ({{year1st.filter((element) => element.id == tourType)[0].is_with_price}} DA)
                </span>
              </span>
              <v-progress-circular
                  v-if="registrationLoad === true"
                  indeterminate
                  color="#FFFFFF"
                  size="20"
              ></v-progress-circular>
            </v-btn>
          </v-row>


        </v-form>
        <v-alert dismissible class="mt-5" v-if="registrationAlert === 1" type="success">   Registration succesfully.</v-alert>
        <v-alert dismissible class="mt-5" v-if="registrationAlert === 2" type="error">   Error</v-alert>

    </div>
  </section>
</template>

<script>
export default {
  name: 'TourRegistration',
  props:{
    year1st: Array,
  },
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      valid: true,
      firstName: '',
      lastName: '',
      gender: null,
      genders: ['Maale', 'Female'],
      genderRules: [
        v => !!v || 'Gender is required',
      ],
      tourType: null,
      tourTypeRules: [
        v => !!v || 'Tour type is required',
      ],
      paymentMethods: ['CCP', 'Baridimob', 'Espace'],
      paymentMethod: null,
      paymentMethodRules: [
        v => !!v || 'Payment method is required',
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 100) || 'Name must be less than 100 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'Phone is required',
        v => /^(00213|\+213|0)(5|6|7)[0-9]{8}$/.test(v) || 'Phone must be valid',
      ],
      registrationLoad: false,
      registrationAlert: 0,
    }
  },
  methods: {
    validate () {
      if(this.$refs.form.validate()) {
        this.registrationLoad = true
        const info = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.phone,
          payment_way: this.paymentMethod,
          gender: this.gender,
          publication_id: this.tourType,
          date_tour: this.date,
        };
        this.$http.post("register", info)
            .then(response => {
              this.registrationLoad = false
              console.log(response.data)
              if(response.data.success === true){
                this.registrationAlert = 1
              }else{
                this.registrationAlert = 2
              }
              this.reset()
            })
            .catch(error => {
              this.registrationLoad = false
              this.reset()
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
      }
    },
    reset () {
      this.$refs.form.reset()
    },
  }

}
</script>

<style scoped>
  .timetable {
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    margin-top: 20px;
  }
  .v-application--wrap {
    flex: 1 1 auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-width: 100%;
    position: relative;
  }
  .v-application {
    flex: 1 1 auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-width: 100%;
    position: relative;
  }
</style>