<template>
  <section class="timetable pb-6">
    <v-row class="text-center">
      <h1 style="font-size: 20px; margin: 20px;">Our Tours</h1>
    </v-row>
    <v-row v-if="year1st.length > 0">
      <v-col align="center" lg="4" md="3" sm="6" cols="12" v-for="timetable in year1st" :key="timetable.id">
        <img style="border-radius: 5px; height: 80px" :src="'https://idevelop.club/saharyan/backend/storage/' + timetable.icon" />
        <h4>{{timetable.title}} | ({{timetable.is_with_price}} DA)</h4>
        <b-button size="is-small" @click="showCardModel(timetable)">
          <v-icon style="margin-bottom: 2px" color="black" size="15">mdi-eye</v-icon>
          view
        </b-button>
      </v-col>
    </v-row>
    <v-row v-else justify="center" align-self="center" align="center">
      <v-col justify="center" align-self="center" align="center">
        <h1 style="font-size: 40px; margin: 20px;">No Data For The Moment</h1>
      </v-col>
    </v-row>
    <b-modal v-model="isCardModalActive" :width="720" scroll="keep">
      <div class="card" style="padding: 15px">
        <div class="card-image">
          <figure class="image is-4by3">
            <img :src="'https://idevelop.club/saharyan/backend/storage/' + currentTimeTable.image" alt="Image">
          </figure>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-96x96">
                <img :src="'https://idevelop.club/saharyan/backend/storage/' + currentTimeTable.icon" alt="Image">
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">{{ currentTimeTable.title }} | ({{ currentTimeTable.is_with_price }} DA)</p>
              <p v-html="currentTimeTable.description" class="subtitle is-6"></p>
            </div>
          </div>
          <div class="content">
            <p v-html="currentTimeTable.content"></p>
            <br>
            <small>{{ currentTimeTable.created_at }}</small>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isCardModalActive: false,
      currentTimeTable: {}
    }
  },
  props:{
    year1st: Array,
  },

  methods: {
    showCardModel(item) {
      this.currentTimeTable = item
      this.isCardModalActive = true
    }
  }

}
</script>

<style scoped>
  .timetable {
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    margin-top: 20px;
  }
</style>