<template>
  <div>
    <div class="columns starter">
      <div class="column has-text-left-desktop has-text-left-tablet has-text-centered-mobile">
        <v-icon :color="'#FDF0D1'" class="element-spacer-right-5px" size="16">mdi-phone</v-icon>
        <span class="element-spacer-right-5px element-spacer-top">{{magasin.phone}}</span>
        <v-icon :color="'#FDF0D1'" class="element-spacer-right-2px" size="16">mdi-fax</v-icon>
        <span class="element-spacer-right-5px element-spacer-top">{{magasin.fax}}</span>
        <v-icon :color="'#FDF0D1'" class="element-spacer-right-2px" size="16">mdi-email</v-icon>
        <span class="element-spacer-top element-spacer-right-5px">{{magasin.email}}</span>

      </div>
      <div class="column has-text-right-desktop has-text-right-tablet has-text-centered-mobile">
        <span>Follow us on: </span>
        <a :href="'//' + magasin.facebook" target="_blank"><v-icon :color="'#FDF0D1'" class="element-spacer-right-5px" size="16">mdi-facebook</v-icon></a>
        <a :href="'//' + magasin.instagram" target="_blank"><v-icon :color="'#FDF0D1'" class="element-spacer-right-5px" size="16">mdi-instagram</v-icon></a>
        <a :href="'//' + magasin.youtube" target="_blank"><v-icon :color="'#FDF0D1'" class="element-spacer-right-5px" size="18">mdi-youtube</v-icon></a>
      </div>
    </div>

    <b-navbar class="my-container" type="is-black" style="margin: 0 !important; font-size: 14px; background-color: #85586F" :fixed-top="isFixed">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
                  :src="'https://idevelop.club/saharyan/backend' + magasin.logo"
                  alt="School Logo"
          >

        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item href="#">
          Gallery
        </b-navbar-item>
        <b-navbar-item href="#">
          Our Tours
        </b-navbar-item>
        <b-navbar-item href="#">
          Registrations
        </b-navbar-item>
        <b-navbar-item href="#">
          Contact
        </b-navbar-item>
      </template>
    </b-navbar>

  </div>

</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Header',
    data: () => ({
      active: 'guide',
      isFixed: false,
      menus: [
        {title: "Home", route:"/"},
        {title: "About", route:"#about"},
        {title: "Our Activities", route:"#activities"},
        {title: "Our Team", route:"#team"},
        {title: "Contact", route:"#contact"},
      ]
    }),
    props:{
      magasin: Object
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll () {
        if(window.scrollY > 100){
          this.isFixed = true
        }else{
          this.isFixed = false
        }
      }
    }
  }
</script>

<style>
  .starter {
    background-color: #AC7D88;
    color: #FDF0D1;
    font-size: 12px;
    margin: 0 !important;
  }
  .starter2 {
    background-color: #643843;
    color: #ECEFF2;
    font-size: 14px;
    margin: 0 !important;
  }
  .my-container {
    padding-left: 50px;
    padding-right: 50px;
  }
  .element-spacer-right-2px {
    margin-right: 2px;
  }
  .element-spacer-right-5px {
    margin-right: 5px;
  }
  .element-spacer-top {
    padding-top: 5px;
  }
</style>