<template>
  <div style="margin-top: 20px">
    <div class="recent-posts-title">
      Our Location
    </div>
    <div class="recent-posts-card">
      <div class="card-content" style="padding-bottom: 20px">
        <iframe style="border:0; width: 100%; height: 230px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3397.3664944864895!2d-2.213669373987276!3d31.623814342026886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd855f01c1a643f9%3A0x76d4cf7e1cba9b84!2z2KzYp9mF2LnYqSDYt9in2YfYsdmKINmF2K3ZhdivINio2LTYp9ixINin2YTYrNiy2KfYptix!5e0!3m2!1sar!2sdz!4v1706232735136!5m2!1sar!2sdz" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolLocation"
}
</script>

<style scoped>
.recent-posts-title {
  background-color: #85586F;
  color: white;
  padding: 15px;
  font-weight: bold;
  border-radius: 3px;
}
.recent-posts-card {
  background-color: white;
  padding: 0 !important;
  margin-top: 1px;
  margin-bottom: 1px;
}
</style>