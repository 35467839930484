<template>
  <b-carousel-list v-model="test" :data="events" :items-to-show="1" >
    <template #item="list">
      <div class="card">
        <div class="card-image">
          <b-image
              :src="'https://idevelop.club/saharyan/backend/storage/' + list.image"
              alt="The Buefy Logo"
              ratio="601by300"
              :rounded="rounded"
          ></b-image>
        </div>
        <div class="card-content">
          <div class="content">
            <p class="title" style="font-size: 18px;">{{ list.title }}</p>
            <p class="subtitle" style="font-size: 12px; padding-top: 5px">
              {{ list.description }} |
              <span class="subtitle" style="font-size: 10px; padding-top: 1px">
                {{ list.created_at }}
              </span>
            </p>

          </div>
        </div>
      </div>
    </template>
  </b-carousel-list>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Carousel",
        props:{
          events: Array
        },
        data() {
            return {
                test: 0,
                rounded: false,
            }
        },
        methods: {
            info(value) {
                this.test = value
            }
        }
    }
</script>

<style>

</style>