<template>
  <div class="footer">
    <v-row class="text-center" style="color: white;" justify="center" align="center" align-content="center">
      <span>
        <v-icon style="margin-bottom: 3px;" color="#FFFFFF">mdi-pound-box</v-icon>  <span>{{magasin.trend}}</span>
      </span>
      <v-spacer></v-spacer>
      <span>
        Copyright © {{magasin.title}} - {{new Date().getFullYear() }}
      </span>
    </v-row>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  data: () => ({

  }),
  props:{
    magasin: Object
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {

    }
  },
}
</script>

<style scoped>
  .footer {
    padding: 30px;
    background-image: linear-gradient(to right, #AC7D88, #85586F);
  }
</style>