<template>
  <div style="margin-top: 20px">
    <div class="recent-posts-title">
      Working Hours
    </div>
    <div class="recent-posts-card">
      <div class="card-content" style="padding-bottom: 0 !important">
        <div class="media">
          <div class="media-left">
            <figure class="image is-64x64">
              <v-icon size="50">mdi-store-clock</v-icon>
            </figure>
          </div>
          <div class="media-content">
            <p class="title" style="font-size: 16px;">
              {{magasin.working_days}}
              <br>
              <small>{{magasin.working_hours}}</small>
            </p>
            <p class="title" style="font-size: 12px;"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkingHours",
  props: {
    magasin: Object
  }
}
</script>

<style scoped>
  .recent-posts-title {
    background-color: #85586F;
    color: white;
    padding: 15px;
    font-weight: bold;
    border-radius: 3px;
  }
  .recent-posts-card {
    background-color: white;
    padding: 0 !important;
    margin-top: 1px;
    margin-bottom: 1px;
  }
</style>