<template>
    <div>
      <div class="recent-posts-title">
        RECENT POSTS
      </div>
      <div class="recent-posts-card" v-for="item in events" :key="item.id" @click="showCardModel(item)">
        <div class="card-content" style="padding-bottom: 0 !important">
          <div class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <img :src="'https://idevelop.club/saharyan/backend/storage/' + item.image" alt="Image">
              </figure>
            </div>
            <div class="media-content">
              <p class="title" style="font-size: 16px;">{{ item.title }}</p>
              <p class="subtitle" style="font-size: 12px;">

                          <span class="subtitle" style="font-size: 10px; padding-top: 1px">
                {{ item.created_at }}
              </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
        <div class="card">
          <div class="card-image" v-if="currentEvent.image != null">
            <figure class="image is-4by3">
              <img :src="'https://idevelop.club/saharyan/backend/storage/' + currentEvent.image" alt="Image">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left" v-if="currentEvent.icon != null">
                <figure class="image is-48x48">
                  <img :src="'https://idevelop.club/saharyan/backend/storage/' + currentEvent.icon" alt="Image">
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4">{{ currentEvent.title }}</p>
                <p v-html="currentEvent.description" class="subtitle is-6"></p>
              </div>
            </div>

            <div class="content">
              <p v-html="currentEvent.content"></p>
              <br>
              <small>{{ currentEvent.created_at }}</small>
            </div>
          </div>
        </div>
      </b-modal>

    </div>
</template>

<script>
    export default {
        name: "RecentPost",
        props:{
          events: Array
        },
        data() {
            return {
              isCardModalActive: false,
              currentEvent: {}
            }
        },
        methods: {
          showCardModel(item) {
            this.currentEvent = item
            this.isCardModalActive = true
          }
        }
    }
</script>

<style scoped>
    .recent-posts-title {
        background-color: #85586F;
        color: white;
        padding: 15px;
        font-weight: bold;
        border-radius: 3px;
    }
    .recent-posts-card {
        background-color: white;
        padding: 0 !important;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .recent-posts-card:hover {
      cursor: pointer;
    }
</style>